var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full mb-2"},[_c('div',{staticClass:"rounded border border-gray-300",class:{ 'opacity-50': _vm.disabled }},[_vm._l((_vm.listOptions),function(item,idx){return _c('div',{key:item.value,staticClass:"flex flex-row items-center px-4",class:{ 'border-t border-gray-300': idx > 0 }},[_c('div',{staticClass:"\n          w-6\n          h-6\n          text-sm\n          flex\n          items-center\n          rounded-sm\n          justify-center\n          bg-blue-300\n          text-blue-800\n        "},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('div',{staticClass:"flex-1 px-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.label),expression:"item.label"}],staticClass:"w-full h-12 text-sm focus:outline-none",attrs:{"disabled":_vm.disabled},domProps:{"value":(item.label)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "label", $event.target.value)}}})]),(!_vm.ro)?_c('a',{staticClass:"\n          h-8\n          w-8\n          rounded\n          flex\n          items-center\n          justify-center\n          text-red-700\n          select-none\n        ",class:{
          'cursor-pointer hover:bg-red-700 hover:text-white': !_vm.disabled,
          'cursor-not-allowed': _vm.disabled
        },on:{"click":function($event){return _vm.onRemoveItem(item)}}},[_vm._v("×")]):_vm._e()])}),(!_vm.disabled && !_vm.ro && _vm.dict.length > 0)?_c('div',{staticClass:"flex flex-row items-center px-4",class:{ 'border-t border-gray-300': _vm.listOptions.length > 0 }},[_c('div',{staticClass:"relative",attrs:{"id":"list-value-picker"}},[(_vm.isPickerShown)?_c('div',{staticClass:"\n            absolute\n            left-0\n            bottom-0\n            mb-8\n            flex flex-row flex-wrap\n            rounded\n            bg-white\n            border border-gray-300\n            w-60\n            shadow\n          "},_vm._l((_vm.availableDict),function(item){return _c('a',{key:item.value,staticClass:"w-8 h-8 flex items-center justify-center",on:{"click":function($event){return _vm.setNewItemValue(item.value)}}},[_c('span',{staticClass:"w-6 h-6 rounded-sm flex items-center justify-center",class:{
                'bg-blue-300 text-blue-800 text-sm': !item.taken,
                'bg-gray-300 text-gray-500 text-sm cursor-not-allowed':
                  item.taken
              }},[_vm._v(_vm._s(item.value))])])}),0):_vm._e(),_c('div',{staticClass:"\n            w-6\n            h-6\n            text-sm\n            flex\n            items-center\n            rounded-sm\n            justify-center\n            bg-blue-300\n            text-blue-800\n          ",on:{"click":_vm.showValuePicker}},[_vm._v(" "+_vm._s(_vm.newItemValue)+" ")])]),_c('div',{staticClass:"flex-1 px-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newItem),expression:"newItem"}],staticClass:"w-full h-12 text-sm focus:outline-none",attrs:{"placeholder":_vm.$t('general.addItem')},domProps:{"value":(_vm.newItem)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onAddNewItem.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.newItem=$event.target.value}}})]),_c('a',{staticClass:"\n          h-8\n          w-8\n          rounded\n          flex\n          items-center\n          justify-center\n          text-active\n          cursor-pointer\n          hover:bg-active hover:text-inactive\n        ",on:{"click":_vm.onAddNewItem}},[_vm._v("+")])]):_vm._e()],2),_c('div',{staticClass:"h-4 text-xs text-red-700"},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('checkbox',{staticClass:"mt-4",attrs:{"i18n":"general.multipleSelect","disabled":_vm.disabled},model:{value:(_vm.multipleSelect),callback:function ($$v) {_vm.multipleSelect=$$v},expression:"multipleSelect"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }