<template>
  <draggable
    tag="div"
    class="flex flex-col rounded shadow-sm bg-white border border-gray-200"
    handle=".handle"
    ghost-class="ghost"
    v-model="wModel"
  >
    <div
      v-for="(item, idx) in value"
      class="flex items-center justify-between border-t"
      :key="item.id"
      :class="{ 'border-gray-200': idx !== 0, 'border-transparent': idx === 0 }"
    >
      <div class="flex items-center">
        <span class="handle cursor-move p-4 flex items-center justify-center">
          <icon face="mdiArrowExpandAll" />
        </span>
        <span class="px-1 py-4"><slot name="left" v-bind:item="item" /></span>
      </div>
      <div class="p-4">
        <slot name="right" v-bind:item="item" />
      </div>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DraggablePanel',
  props: ['value'],
  computed: {
    wModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    draggable
  }
}
</script>
