var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('article',{staticClass:"mx-2"},[_c('div',{staticClass:"mx-auto max-w-lg"},[_c('draggable-panel',{attrs:{"value":_vm.procHeaders},on:{"input":_vm.saveHeaders},scopedSlots:_vm._u([{key:"left",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-blue-500 hover:text-blue-700",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showEditItem(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"right",fn:function(ref){
var item = ref.item;
return [_c('chip',{staticClass:"mx-px",attrs:{"size":"sm"}},[_vm._v(_vm._s(item.fieldTypeName))]),_c('button',{staticClass:"mx-px",class:{ 'opacity-60 cursor-not-allowed': item.main },attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleShow(item)}}},[(item.show)?_c('chip',{attrs:{"size":"xs","variant":"success"}},[_c('icon',{attrs:{"face":"mdiEye"}})],1):_c('chip',{attrs:{"size":"xs","variant":"danger"}},[_c('icon',{attrs:{"face":"mdiEyeOffOutline"}})],1)],1),(_vm.taggable)?_c('button',{staticClass:"mx-px",class:{
            'opacity-30 cursor-not-allowed': !_vm.isTagEditingAllowed(item)
          },attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleTag(item)}}},[(item.tag)?_c('chip',{attrs:{"size":"sm","variant":"success"}},[_c('icon',{attrs:{"face":"mdiTagText"}})],1):(!item.tag)?_c('chip',{attrs:{"size":"sm","variant":"danger"}},[_c('icon',{attrs:{"face":"mdiTagOffOutline"}})],1):_vm._e()],1):_vm._e()]}}],null,false,2526877879)}),(_vm.isAdmin)?_c('div',{staticClass:"mt-4"},[_c('action',{staticClass:"w-full",attrs:{"variant":"primary"},on:{"click":_vm.showAddItem}},[_vm._v(_vm._s(_vm.$t('actions.add')))])],1):_vm._e()],1),(_vm.currentItem)?_c('modal',{attrs:{"title":_vm.currentItem.id === '#add'
        ? _vm.$t('actions.addName', _vm.currentItem)
        : _vm.$t('actions.editName', _vm.currentItem)},on:{"close":_vm.hideEditItem}},[_c('field',{attrs:{"i18n":"fieldTypes.name","type":"text","disabled":!_vm.isAdmin || _vm.isBusy},model:{value:(_vm.currentItem.name),callback:function ($$v) {_vm.$set(_vm.currentItem, "name", $$v)},expression:"currentItem.name"}}),_c('selector',{attrs:{"i18n":"fieldTypes.title","options":_vm.fieldTypes,"disabled":_vm.currentItem.id !== '#add' || _vm.isBusy},model:{value:(_vm.currentItem.type),callback:function ($$v) {_vm.$set(_vm.currentItem, "type", $$v)},expression:"currentItem.type"}}),(_vm.currentItem.type === 'L')?_c('header-settings-list',{attrs:{"item":_vm.currentItem,"disabled":_vm.isBusy || _vm.currentItem.ro,"error":_vm.headerSettingsErrors.l
          ? _vm.$t(("validation." + (_vm.headerSettingsErrors.l)))
          : undefined},model:{value:(_vm.currentItem.source),callback:function ($$v) {_vm.$set(_vm.currentItem, "source", $$v)},expression:"currentItem.source"}}):_vm._e(),_c('checkbox',{attrs:{"i18n":"column.show","disabled":_vm.currentItem.main || _vm.procHeaders.length === 1 || _vm.isBusy},model:{value:(_vm.currentItem.show),callback:function ($$v) {_vm.$set(_vm.currentItem, "show", $$v)},expression:"currentItem.show"}}),(_vm.taggable)?_c('checkbox',{attrs:{"i18n":"column.tag","disabled":!_vm.isTagEditingAllowed(_vm.currentItem)},model:{value:(_vm.currentItem.tag),callback:function ($$v) {_vm.$set(_vm.currentItem, "tag", $$v)},expression:"currentItem.tag"}}):_vm._e(),(_vm.taggable && _vm.isInvertable(_vm.currentItem))?_c('checkbox',{attrs:{"i18n":"column.positionInvert","disabled":!_vm.isTagEditingAllowed(_vm.currentItem)},model:{value:(_vm.currentItem.inverted),callback:function ($$v) {_vm.$set(_vm.currentItem, "inverted", $$v)},expression:"currentItem.inverted"}}):_vm._e(),_c('div',{staticClass:"flex justify-between items-center space-x-1"},[_c('div',{staticClass:"flex items-center"},[(_vm.canDeleteCurrentItem)?_c('confirm',{on:{"click":_vm.onDeleteCurrentItem}}):_vm._e()],1),_c('div',{staticClass:"flex items-center"},[_c('action',{attrs:{"variant":"link","type":"button","disabled":_vm.isBusy},on:{"click":_vm.hideEditItem}},[_vm._v(_vm._s(_vm.$t('actions.cancel')))]),_c('action',{attrs:{"variant":"primary","disabled":_vm.isBusy,"loading":_vm.isBusy},on:{"click":_vm.onSaveCurrentItem}},[_vm._v(_vm._s(_vm.$t('actions.save')))])],1)])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }