<template>
  <article v-if="loaded" class="mx-2">
    <div class="mx-auto max-w-lg">
      <draggable-panel :value="procHeaders" @input="saveHeaders">
        <template #left="{ item }">
          <a
            class="text-blue-500 hover:text-blue-700"
            href="#"
            @click.prevent="showEditItem(item)"
            >{{ item.name }}</a
          >
        </template>
        <template #right="{ item }">
          <chip size="sm" class="mx-px">{{ item.fieldTypeName }}</chip>
          <button
            @click="toggleShow(item)"
            type="button"
            class="mx-px"
            :class="{ 'opacity-60 cursor-not-allowed': item.main }"
          >
            <chip v-if="item.show" size="xs" variant="success">
              <icon face="mdiEye" />
            </chip>
            <chip v-else size="xs" variant="danger">
              <icon face="mdiEyeOffOutline" />
            </chip>
          </button>
          <button
            type="button"
            v-if="taggable"
            class="mx-px"
            @click="toggleTag(item)"
            :class="{
              'opacity-30 cursor-not-allowed': !isTagEditingAllowed(item)
            }"
          >
            <chip size="sm" variant="success" v-if="item.tag"
              ><icon face="mdiTagText"
            /></chip>
            <chip size="sm" variant="danger" v-else-if="!item.tag"
              ><icon face="mdiTagOffOutline"
            /></chip>
          </button>
        </template>
      </draggable-panel>
      <div v-if="isAdmin" class="mt-4">
        <action variant="primary" class="w-full" @click="showAddItem">{{
          $t('actions.add')
        }}</action>
      </div>
    </div>
    <modal
      v-if="currentItem"
      @close="hideEditItem"
      :title="
        currentItem.id === '#add'
          ? $t('actions.addName', currentItem)
          : $t('actions.editName', currentItem)
      "
    >
      <field
        i18n="fieldTypes.name"
        type="text"
        v-model="currentItem.name"
        :disabled="!isAdmin || isBusy"
      />
      <selector
        i18n="fieldTypes.title"
        v-model="currentItem.type"
        :options="fieldTypes"
        :disabled="currentItem.id !== '#add' || isBusy"
      />
      <header-settings-list
        v-if="currentItem.type === 'L'"
        :item="currentItem"
        v-model="currentItem.source"
        :disabled="isBusy || currentItem.ro"
        :error="
          headerSettingsErrors.l
            ? $t(`validation.${headerSettingsErrors.l}`)
            : undefined
        "
      ></header-settings-list>
      <checkbox
        i18n="column.show"
        v-model="currentItem.show"
        :disabled="currentItem.main || procHeaders.length === 1 || isBusy"
      />
      <checkbox
        v-if="taggable"
        v-model="currentItem.tag"
        i18n="column.tag"
        :disabled="!isTagEditingAllowed(currentItem)"
      />
      <checkbox
        v-if="taggable && isInvertable(currentItem)"
        v-model="currentItem.inverted"
        i18n="column.positionInvert"
        :disabled="!isTagEditingAllowed(currentItem)"
      />
      <div class="flex justify-between items-center space-x-1">
        <div class="flex items-center">
          <confirm @click="onDeleteCurrentItem" v-if="canDeleteCurrentItem" />
        </div>
        <div class="flex items-center">
          <action
            variant="link"
            type="button"
            :disabled="isBusy"
            @click="hideEditItem"
            >{{ $t('actions.cancel') }}</action
          >
          <action
            variant="primary"
            :disabled="isBusy"
            :loading="isBusy"
            @click="onSaveCurrentItem"
            >{{ $t('actions.save') }}</action
          >
        </div>
      </div>
    </modal>
  </article>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getProcHeaders, extractHeaders } from '@/services'
import { getFieldTypeName, fieldTypes } from '@/config'
import DraggablePanel from './DraggablePanel'
import { HeaderSettingsList } from './form/meta'

export default {
  components: {
    'draggable-panel': DraggablePanel,
    'header-settings-list': HeaderSettingsList
  },
  props: {
    name: { type: String, required: true },
    taggable: { type: Boolean, default: false }
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) =>
        state.settings.loading ||
        state.settings.updating ||
        state.headers.loading ||
        state.headers.updating,
      isAdmin: (state) => state.user?.role === 'ADMIN',
      settings: (state) => state.settings,
      headers: (state) => state.headers
    }),
    _settings() {
      return this.settings.items.find((item) => item.name === this.name)
    },
    _headers() {
      return this.headers.items.find((item) => item.name === this.name)
    },
    procHeaders() {
      return getProcHeaders(this._settings.items, this._headers?.items).map(
        (item) => ({
          ...item,
          fieldTypeName: getFieldTypeName(item)
        })
      )
    },
    allowedTypesTag() {
      return fieldTypes.reduce(
        (acc, field) => ({
          ...acc,
          [field.value]: !!field.taggable
        }),
        {}
      )
    },
    fieldTypes() {
      return fieldTypes.map(({ value, label, disabled }) => ({
        id: value,
        value,
        label,
        disabled
      }))
    },
    canDeleteCurrentItem() {
      return (
        !!this.currentItem &&
        this.isAdmin &&
        !this.currentItem.ro &&
        !this.currentItem.main &&
        this._settings.items.length > 1
      )
    },
    hasCurrentItemMeta() {
      if (!this.currentItem) {
        return false
      }

      return this.currentItem.type === 'L'
    }
  },
  data() {
    return {
      currentItem: null,
      headerSettingsErrors: {}
    }
  },
  methods: {
    ...mapActions(['settings/update', 'headers/create', 'headers/update']),
    onChangeMeta({ prop, value }) {
      if (!this.currentItem) {
        return false
      }
      this.$set(this.currentItem, prop, value)
    },
    isTagEditingAllowed(item = null) {
      if (!this.taggable || !this.isAdmin) {
        return false
      }
      const _item = item || this.currentItem
      if (!_item) {
        return false
      }
      return !!this.allowedTypesTag[_item.type]
    },
    isInvertable(item = null) {
      const _item = item || this.currentItem
      return this.taggable && (_item.type === 'S' || _item.type === 'N')
    },
    computeNewID() {
      const c = `c${Math.floor(100000000 * Math.random())}`
      const found = this._settings.items.find((item) => item.id === c)
      return !found ? c : this.computeNewID()
    },
    showAddItem() {
      if (!this.isAdmin) {
        return false
      }
      this.currentItem = {
        id: '#add',
        name: this.$t('form.untitled'),
        fill: true,
        ro: false,
        show: true,
        type: 'T',
        ...(this.taggable ? { tag: false, inverted: false } : {})
      }
    },
    showEditItem(item) {
      if (!this.isAdmin) {
        return false
      }
      this.currentItem = JSON.parse(JSON.stringify(item))
    },
    hideEditItem() {
      if (this.isBusy) {
        return
      }
      this.currentItem = null
    },
    async toggleShow(item) {
      const items = this.procHeaders.slice(0)

      const idx = items.findIndex((found) => found.id === item.id)
      if (idx < 0) {
        console.error('Could not find ', item)
        return
      }
      items.splice(idx, 1, {
        ...JSON.parse(JSON.stringify(item)),
        show: !item.show
      })
      await this.saveHeaders(items)
    },
    async toggleTag(item) {
      if (!this.isTagEditingAllowed(item)) {
        return false
      }

      const items = this.procHeaders.slice(0)

      const idx = items.findIndex((found) => found.id === item.id)
      if (idx < 0) {
        return
      }
      items.splice(idx, 1, {
        ...JSON.parse(JSON.stringify(item)),
        tag: !item.tag
      })
      await this.saveSettings(items)
    },
    async onSaveCurrentItem() {
      if (!this.currentItem || !this.isAdmin) {
        this.currentItem = null
        return true
      }

      this.headerSettingsErrors = {}

      if (
        this.currentItem.type !== 'L' &&
        this.currentItem.type !== 'C' &&
        this.currentItem.source
      ) {
        delete this.currentItem.source
      }

      if (this.currentItem.type === 'L') {
        if (!this.currentItem.source) {
          this.headerSettingsErrors.l = 'required'
          return false
        }
        if (!this.currentItem.source.options) {
          this.headerSettingsErrors.l = 'required'
          return false
        }
        if (!this.currentItem.source.options.length) {
          this.headerSettingsErrors.l = 'required'
          return false
        }
      }

      const items = this.procHeaders.slice(0)
      if (this.currentItem.id === '#add') {
        items.push({
          ...this.currentItem,
          id: this.computeNewID()
        })
      } else {
        const idx = items.findIndex((item) => item.id === this.currentItem.id)
        if (idx >= 0) {
          items.splice(idx, 1, this.currentItem)
        }
      }
      try {
        await this.saveSettings(items)
        await this.saveHeaders(items)
      } catch (err) {
        console.error('Error caught:', err)
      } finally {
        this.currentItem = null
      }
    },
    async onDeleteCurrentItem() {
      if (!this.canDeleteCurrentItem) {
        this.hideEditItem()
        return
      }

      const items = this.procHeaders.slice(0)

      const idx = items.findIndex((item) => item.id === this.currentItem.id)

      if (idx < 0) {
        this.hideEditItem()
        return
      }

      items.splice(idx, 1)

      try {
        await this.saveSettings(items)
        await this.saveHeaders(items)
      } catch (err) {
        console.error('Error caught:', err)
      } finally {
        this.currentItem = null
      }
    },
    async saveSettings(items) {
      return this['settings/update']({
        ...this._settings,
        items
      })
    },
    async saveHeaders(ph) {
      const items = extractHeaders(ph)
      if (this._headers && this._headers?._id) {
        const { _id } = this._headers
        await this['headers/update']({ _id, name: this.name, items })
      } else {
        await this['headers/create']({ name: this.name, items })
      }
    }
  }
}
</script>
