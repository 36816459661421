<template>
  <div class="w-full mb-2">
    <div
      class="rounded border border-gray-300"
      :class="{ 'opacity-50': disabled }"
    >
      <div
        v-for="(item, idx) in listOptions"
        :key="item.value"
        class="flex flex-row items-center px-4"
        :class="{ 'border-t border-gray-300': idx > 0 }"
      >
        <div
          class="
            w-6
            h-6
            text-sm
            flex
            items-center
            rounded-sm
            justify-center
            bg-blue-300
            text-blue-800
          "
        >
          {{ item.value }}
        </div>
        <div class="flex-1 px-4">
          <input
            class="w-full h-12 text-sm focus:outline-none"
            v-model="item.label"
            :disabled="disabled"
          />
        </div>
        <a
          v-if="!ro"
          class="
            h-8
            w-8
            rounded
            flex
            items-center
            justify-center
            text-red-700
            select-none
          "
          :class="{
            'cursor-pointer hover:bg-red-700 hover:text-white': !disabled,
            'cursor-not-allowed': disabled
          }"
          @click="onRemoveItem(item)"
          >&times;</a
        >
      </div>
      <div
        class="flex flex-row items-center px-4"
        :class="{ 'border-t border-gray-300': listOptions.length > 0 }"
        v-if="!disabled && !ro && dict.length > 0"
      >
        <div class="relative" id="list-value-picker">
          <div
            v-if="isPickerShown"
            class="
              absolute
              left-0
              bottom-0
              mb-8
              flex flex-row flex-wrap
              rounded
              bg-white
              border border-gray-300
              w-60
              shadow
            "
          >
            <a
              v-for="item in availableDict"
              class="w-8 h-8 flex items-center justify-center"
              :key="item.value"
              @click="setNewItemValue(item.value)"
            >
              <span
                class="w-6 h-6 rounded-sm flex items-center justify-center"
                :class="{
                  'bg-blue-300 text-blue-800 text-sm': !item.taken,
                  'bg-gray-300 text-gray-500 text-sm cursor-not-allowed':
                    item.taken
                }"
                >{{ item.value }}</span
              >
            </a>
          </div>
          <div
            class="
              w-6
              h-6
              text-sm
              flex
              items-center
              rounded-sm
              justify-center
              bg-blue-300
              text-blue-800
            "
            @click="showValuePicker"
          >
            {{ newItemValue }}
          </div>
        </div>
        <div class="flex-1 px-4">
          <input
            class="w-full h-12 text-sm focus:outline-none"
            v-model="newItem"
            :placeholder="$t('general.addItem')"
            @keydown.enter="onAddNewItem"
          />
        </div>
        <a
          @click="onAddNewItem"
          class="
            h-8
            w-8
            rounded
            flex
            items-center
            justify-center
            text-active
            cursor-pointer
            hover:bg-active hover:text-inactive
          "
          >+</a
        >
      </div>
    </div>
    <div class="h-4 text-xs text-red-700">
      {{ error }}
    </div>
    <checkbox
      class="mt-4"
      v-model="multipleSelect"
      i18n="general.multipleSelect"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: 'HeaderSettingsList',
  props: {
    item: Object,
    value: Object,
    disabled: Boolean,
    error: String
  },
  computed: {
    ro() {
      return !!this.item?.ro
    },
    dict() {
      const dict = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
      return dict
    },
    availableDict() {
      return this.dict.map((letter) => ({
        value: letter,
        taken: this.isLetterTaken(letter)
      }))
    },
    listOptions() {
      let options = this.value?.options?.slice(0) ?? []
      if (this.ro) {
        options = options.map((item) => ({
          ...item,
          label: this.$t(item.label)
        }))
      }
      return options
    },
    multipleSelect: {
      get() {
        return !this.value?.single
      },
      set(value) {
        this.$emit('input', {
          single: !value,
          options: this.value.options
        })
      }
    }
  },
  data() {
    return {
      newItem: '',
      newItemValue: '?',
      isPickerShown: false
    }
  },
  mounted() {
    this.newItemValue = this.getNewItemValue()
    document.addEventListener('click', this.onDocumentClick)
    if (!this.value) {
      this.$emit('input', { single: true, options: [] })
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick)
  },
  methods: {
    onDocumentClick(event) {
      if (!this.isPickerShown) {
        return false
      }
      const picker = document.getElementById('list-value-picker')
      if (!picker) {
        return false
      }
      if (!picker.contains(event.target)) {
        this.hideValuePicker()
      }
    },
    showValuePicker() {
      this.isPickerShown = true
    },
    hideValuePicker() {
      this.isPickerShown = false
    },
    getNewItemValue() {
      return this.availableDict.find((item) => !item.taken)?.value ?? '?'
    },
    setNewItemValue(letter) {
      const isAvailable = this.availableDict.find(
        (item) => item.value === letter
      )
      if (isAvailable.taken) {
        return
      }
      this.newItemValue = letter
      this.isPickerShown = false
    },
    isLetterTaken(letter) {
      if (!this.listOptions || this.listOptions.length === 0) {
        return false
      }
      return !!this.listOptions.find((item) => item.value === letter)
    },
    onAddNewItem() {
      const ne = this.newItem.trim()
      if (ne.length > 0) {
        const options = this.listOptions.slice(0)
        options.push({
          value: this.newItemValue,
          label: ne
        })
        this.$emit('input', { single: this.value.single, options })
      }
      this.$nextTick(() => {
        this.newItem = ''
        this.newItemValue = this.getNewItemValue()
      })
    },
    onRemoveItem(item) {
      const options = this.listOptions.slice(0)
      const idx = options.findIndex((found) => found.value === item.value)
      if (idx >= 0) {
        options.splice(idx, 1)
        this.$emit('input', { single: this.value.single, options })
        this.$nextTick(() => {
          this.newItemValue = this.getNewItemValue()
        })
      }
    }
  }
}
</script>
